import React from 'react';
import "../App.css"
function Hud({ onClose }) {
    return (
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000',
        }}>
            <div style={{
                width: '150px',
                height: 'auto',
                backgroundColor: 'white',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                textAlign: 'center',
            }}>
                <div style={{
                    width: '40px',
                    height: '40px',
                    marginTop: '15px',
                    marginLeft:'50px',
                    borderRadius: '50%',
                    border: '5px solid rgba(0, 0, 0, 0.1)',
                    borderTopColor: '#000000',
                    animation: 'spin 1s linear infinite',
                }}></div>
                <p style={{ marginBottom: '0px', marginTop:'15px', fontSize: '17px' }}>Please wait...</p><br />
            </div>
        </div>
    );
}

export default Hud;
