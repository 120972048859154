import React, { useState } from 'react';
import { HiUser } from "react-icons/hi";
import UserPool from '../UserPool';
import { CognitoUser } from "amazon-cognito-identity-js";
import { NavLink, useNavigate } from 'react-router-dom';
import Alert from './Alert';
import Hud from './Hud';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // New state variable
  const[showHud,setShowHud]=useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({ email: '' });
  const [focused, setFocused] = useState({
    email: false,
  });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    let emailError = '';

    if (!email) {
      emailError = 'Email is required.';
      valid = false;
    } else if (!isValidEmailFormat(email)) {
      emailError = 'Please enter a valid email.';
      valid = false;
    }
    setErrors({ email: emailError });

    if (!isValidEmailFormat(email)) {
      setIsValidEmail(false);
      return;
    }
    setShowHud(true);
    const userData = {
      Username: email,
      Pool: UserPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        setShowHud(false)
        console.log("Password reset initiated successfully", data);
        setAlertMessage('Reset code has been sent to your email.');
        setIsSuccess(true); // Set success to true
        setShowAlert(true);
      },
      onFailure: (err) => {
        setShowHud(false)
        console.error("Error initiating password reset", err);
        setAlertMessage(err.message);
        setIsSuccess(false); // Set success to false
        setShowAlert(true);
      },
    });
  };

  const isValidEmailFormat = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const toggleHud = () => {
    setShowHud(!showHud);
    
  };
  const handleAlertClose = () => {
    setShowAlert(false);
    if (isSuccess) {
      navigate('/PasswordValidation', { state: { email } });
    }
  };

  const getInputStyle = (field, value, isFocused) => {
    let borderColor = '#f3f3f3'; // Default border color
    if (isFocused) {
      borderColor = '#397fc4';
    }

    return {
      width: '100%',
      padding: '15px',
      boxSizing: 'border-box',
      backgroundColor: '#f2f2f2',
      outline: 'none',
      border: `1px solid ${borderColor}`,
      borderRadius: '5px'
    };
  };

  return (
    <div style={{
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#d3d4cf'
    }}>
      <div style={{
        backgroundColor: 'white',
        width: '400px',
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        position: 'relative',
        boxSizing: 'border-box'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '-55px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100px',
          height: '100px',
          borderRadius: '50%',
          backgroundColor: '#3b7bc4',
          color: 'white'
        }}>
          <HiUser style={{ width: '100%', height: '100%', padding: '10px' }} />
        </div>
        <form onSubmit={handleSubmit} style={{ width: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '60px' }}>
          <p style={{ width: '120%', textAlign: 'center', marginLeft: '60px', color: '#626262' }}>Forgot your password? Enter your email address, and we'll send a reset code to your email.</p><br />
          <div style={{ marginLeft: '60px', width: '120%' }}>
            <input
              type='text'
              value={email}
              onChange={handleEmailChange}
              onFocus={() => setFocused(prevState => ({ ...prevState, email: true }))}
              onBlur={() => setFocused(prevState => ({ ...prevState, email: false }))}
              placeholder='Enter Email'
              style={getInputStyle('email', email, focused.email)}
              required
            />
            {errors.email && <span style={{ color: 'red', fontSize: "12px" }}>{errors.email}</span>}
          </div>

          <br />
          <button type="submit" style={{
            width: '120%',
            padding: '15px',
            marginLeft: '60px',
            backgroundColor: '#397fc4',
            color: 'white',
            border: 'none',
            fontSize: '20px',
            borderRadius: '4px',
            cursor: 'pointer'
          }}>Submit</button><br />

          <div style={{
            display: "flex",
            justifyContent: "space-between",
            width: "120%",
          }}>
            <NavLink to="/Signup" exact >
              <p style={{ marginLeft: '30px', color: '#397fc4' }}>Create Account</p>
            </NavLink>
            <NavLink to='/' exact >
              <p style={{ marginRight: '-20px', color: '#397fc4' }}>Signin</p>
            </NavLink>
          </div>
        </form>
        {showAlert && (
          <Alert message={alertMessage} onClose={handleAlertClose} />
        )}
           {showHud && <Hud onClose={toggleHud} />}
      </div>
    </div>
  );
}

export default ForgotPassword;