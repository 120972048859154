import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HiUser } from 'react-icons/hi'; // Assuming you have react-icons installed
import Hud from './Hud';

import UserPool from '../UserPool';
import Alert from './Alert';

const Signup = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '', rePassword: '' });
    const [focused, setFocused] = useState({
        email: false,
        password: false,
        rePassword: false,
    });


    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    };

    const handleRePasswordChange = (e) => {
        const value = e.target.value;
        setRePassword(value);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        // Regular expressions for password validation
        const lengthRegex = /.{8,}/;
        const numberRegex = /\d+/;
        const symbolRegex = /[\W_]+/;
        const uppercaseRegex = /[A-Z]+/;
        const lowercaseRegex = /[a-z]+/;

        // Check if password meets all criteria
        const isValid =
            lengthRegex.test(password) &&
            numberRegex.test(password) &&
            symbolRegex.test(password) &&
            uppercaseRegex.test(password) &&
            lowercaseRegex.test(password);

        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let valid = true;
        let emailError = '';
        let passwordError = '';
        let rePasswordError = '';

        if (!email) {
            emailError = 'Email is required.';
            valid = false;
        } else if (!validateEmail(email)) {
            emailError = 'Please enter a valid email.';
            valid = false;
        }

        if (!password) {
            passwordError = 'Password is required.';
            valid = false;
        } else if (!validatePassword(password)) {
            passwordError = 'Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
            valid = false;
        }

        if (!rePassword) {
            rePasswordError = 'Please re-enter your password.';
            valid = false;
        } else if (password !== rePassword) {
            rePasswordError = 'Passwords do not match.';
            valid = false;
        }

        setErrors({ email: emailError, password: passwordError, rePassword: rePasswordError });

        if (valid) {
            setIsLoading(true);
            try {

                UserPool.signUp(email, password,[],null, (err,data) =>{
                 if(err)
                 {
                     console.log(err);
                     setShowAlert(true);
                     setAlertMessage(err);
                 }
                 console.log(data);
                 setShowAlert(true);
                 setAlertMessage("Signup completed",data);
                 setEmail('');
                 setPassword('');
                 setRePassword('');
                 setErrors({ email: '', password: '', rePassword: '' });
                 setIsLoading(false);
                })
         
             } catch (err) {
                setIsLoading(false);
                setShowAlert(true);
                setAlertMessage(err.message);
               
             }

            
            
        }
    };
    const getInputStyle = (field, value, isFocused) => {
        let borderColor = '#f3f3f3'; // Default border color
        if (isFocused) {
            borderColor = '#397fc4';
        } else if (errors[field]) {
            borderColor = 'red';
        } else if (field !== 'email' && value.length >= 8) {
            borderColor = 'green';
        } else if (field === 'email' && !errors.email && value && validateEmail(value)) {
            borderColor = 'green';
        }

        return {
            width: '100%',
            padding: '15px',
            boxSizing: 'border-box',
            backgroundColor: '#f2f2f2',
            outline: 'none',
            border: `1px solid ${borderColor}`,
            borderRadius: '5px'
        };
    };

    const toggleHud = () => {
        setIsLoading(!isLoading);
    };

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    return (
        <div style={{
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#d3d4cf'
        }}>
            <div style={{
                backgroundColor: 'white',
                width: '400px',
                padding: '20px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
                position: 'relative',
                boxSizing: 'border-box'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '-55px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    backgroundColor: '#397fc4',
                    color: 'white'
                }}>
                    <HiUser style={{ width: '100%', height: '100%', padding: '10px' }} />
                </div>
                <form onSubmit={handleSubmit} style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '60px'
                }}>
                    <p style={{ fontSize: "20px", marginBottom: "20px", fontFamily: "Arial",marginTop:"0px",color:'#397fc4' }}>Registration</p>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                        <input
                            type='text'
                            value={email}
                            onChange={handleEmailChange}
                            placeholder='Enter Email'
                            onFocus={() => setFocused(prevState => ({ ...prevState, email: true }))}
                            onBlur={() => setFocused(prevState => ({ ...prevState, email: false }))}
                            required
                            style={getInputStyle('email', email, focused.email)}
                        />
                        {errors.email && <span style={{ color: 'red', fontSize: "12px" }}>{errors.email}</span>}
                    </div>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                        <input
                            type='password'
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder='Enter Password'
                            onFocus={() => setFocused(prevState => ({ ...prevState, password: true }))}
                            onBlur={() => setFocused(prevState => ({ ...prevState, password: false }))}
                            required
                            style={getInputStyle('password', password, focused.password)}
                        />
                        {errors.password && <span style={{ color: 'red', fontSize: "12px" }}>{errors.password}</span>}
                    </div>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                        <input
                            type='password'
                            value={rePassword}
                            onChange={handleRePasswordChange}
                            placeholder='Re-Enter Password'
                            onFocus={() => setFocused(prevState => ({ ...prevState, rePassword: true }))}
                            onBlur={() => setFocused(prevState => ({ ...prevState, rePassword: false }))}
                            required
                            style={getInputStyle('rePassword', rePassword, focused.rePassword)}
                        />
                        {errors.rePassword && <span style={{ color: 'red', fontSize: "12px" }}>{errors.rePassword}</span>}
                    </div>
                    <button type="submit" style={{
                        width: '100%',
                        padding: '15px',
                        backgroundColor: '#397fc4',
                        color: 'white',
                        border: 'none',
                        fontSize: '20px',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}>Sign Up</button>
                  
                    <div style={{ marginTop: '20px', display: 'inline-flex' }}>
                        <p>Already have a account? </p>&nbsp;
                        <NavLink to="/" exact>
                            <p> Signin</p>
                        </NavLink>
                    </div>
                    {isLoading &&  <Hud onClose={toggleHud} />}
                </form>
            </div>
            {showAlert && (
                        <Alert message={alertMessage} onClose={handleAlertClose} />
                    )}
        </div>
    );
};

export default Signup;
