import React, { useState, useEffect } from 'react';
import { FaCogs } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomeScreen.css';  // Ensure this CSS file is created and imported
import { IoMdSettings, IoMdLogOut } from "react-icons/io";
import Hud from './Hud';
import Alert from './Alert';

const HomeScreen = () => {
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMake, setSelectedMake] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedSubModel, setSelectedSubModel] = useState('');
    const [years, setYears] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [submodels, setSubModels] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const navigate = useNavigate();
    const [showHud, setShowHud] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [planName, setPlanName] = useState('');

    const [leftBoxWidth, setLeftBoxWidth] = useState(400); // Initial width of the left box
    const [isResizing, setIsResizing] = useState(false);
    const [initialMouseX, setInitialMouseX] = useState(0);

    const MIN_WIDTH = 400;
    const MAX_WIDTH = 700;

    const handleMouseDown = (e) => {
        setIsResizing(true);
        setInitialMouseX(e.clientX);

    };

    // Event handler to stop resizing
    const handleMouseUp = () => {
        setIsResizing(false);
    };

    // Event handler to resize the left box
    const handleMouseMove = (e) => {
        if (isResizing) {
            const delta = e.clientX - initialMouseX;
            setLeftBoxWidth((prevWidth) => {
                const newWidth = prevWidth + delta;
                if (newWidth < MIN_WIDTH) return MIN_WIDTH;
                if (newWidth > MAX_WIDTH) return MAX_WIDTH;
                return newWidth;
            });
            setInitialMouseX(e.clientX);
        }
    };

    useEffect(() => {
        if (isResizing) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing, handleMouseMove]);

    useEffect(() => {
        window.history.forward();
        const handlebackbutton = () => {
            window.history.forward();
        };
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handlebackbutton);
        return () => {
            window.removeEventListener('popstate', handlebackbutton);
        };
    }, []);


    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (showDropdown && event.target.id !== 'dropdown') {
                const button = document.getElementById('dropdown');
                if (button && !button.contains(event.target)) {
                    closePop();
                }
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [showDropdown, closePop]);

    useEffect(() => {
            setShowHud(true);
            axios.get('http://vmapi-stage.us-east-1.elasticbeanstalk.com/api/maint_search/MaintPortal_Years_GET')
                .then(response => {
                    console.log("years list : ", response.data.yearList)
                 


                    if(response.data.yearList.length >0)
                    {
                        const sortedYears = response.data.yearList
                        .map(item => item.year)
                        .sort((a, b) => a - b);
                        setYears(sortedYears);
                        setShowHud(false);
                    }
                    else{
                        setShowHud(false);
                        setShowAlert(true)
                        setAlertMessage("No Years")
                    }

                })
                .catch(error => {
                    console.error('Error fetching years:', error);
                    setShowHud(false);
                    setShowAlert(true)
                    setAlertMessage('Years get: '+error.message)
                });
        
    }, []);

    useEffect(() => {
        if (selectedYear) {
            setShowHud(true);
            axios.post('http://vmapi-stage.us-east-1.elasticbeanstalk.com/api/maint_search/MaintPortal_Makes_GET',{ year: selectedYear })
                .then(response => {
                    console.log('MaintPortMaintPortal_Makes_GET:', response.data.makeList);
                  
                    if(response.data.makeList.length >0)
                    {
                        setMakes(response.data.makeList.map(item => item.make));
                        setShowHud(false);
                    }
                    else{
                        setShowHud(false);
                        setShowAlert(true)
                        setAlertMessage("No Makes")
                    }
                })
                .catch(error => {
                    console.error('Error fetching makes:', error);
                    setShowHud(false);
                    setShowAlert(true)
                    setAlertMessage('Makes get: '+error.message)
                });
        }
    

    }, [selectedYear]);

    useEffect(() => {
        if (selectedMake) {
            setShowHud(true);
            const reqObj = { make: selectedMake, year:selectedYear };
            console.log('MaintPortal_Models_GET is:', reqObj);

            axios.post('http://vmapi-stage.us-east-1.elasticbeanstalk.com/api/maint_search/MaintPortal_Models_GET', reqObj)
                .then(response => {
                    console.log('MaintPortal_Models_GET:', response.data);
    
                    if (response.data.modelList && response.data.modelList.length > 0) {
                        // Add a unique ID to each model item
                        // const modelListWithIds = response.data.modelList.map((modelItem, index) => ({
                        //     ...modelItem,
                        //     id: `${modelItem.model}-${modelItem.engine}-${modelItem.submodel}-${index}`
                        // }));

                        console.log("response.data.modelList:",response.data.modelList)
                        setModels(response.data.modelList.map(item => item.model));
                    } else {
                        setModels([]);
                        setShowAlert(true);
                        setAlertMessage("No Models");
                    }
                    setShowHud(false);
                })
                .catch(error => {
                    console.error('Error fetching models:', error);
                    setShowHud(false);
                    setShowAlert(true);
                    setAlertMessage('Models get: ' + error.message);
                });
        } else {
            setModels([]);
        }
    }, [selectedMake,selectedYear]);
    
    useEffect(() => {
        if (selectedModel) {
            setShowHud(true);
            // const modelDetails = models.find(modelItem => modelItem.id === selectedModel);

            const reqObj = {year: selectedYear, make: selectedMake, model: selectedModel};
            console.log('MaintPortal_SubModels_GET is:', reqObj);

            axios.post('http://vmapi-stage.us-east-1.elasticbeanstalk.com/api/maint_search/MaintPortal_SubModels_GET', reqObj)
                .then(response => {
                    console.log('MaintPortal_SubModels_GET:', response.data);
    
                    if (response.data.subModelList && response.data.subModelList.length > 0) {
                        // Add a unique ID to each model item
                        const modelListWithIds = response.data.subModelList.map((modelItem, index) => ({
                            ...modelItem,
                            id: `${modelItem.engine}-${modelItem.submodel}-${index}`
                        }));
                        setSubModels(modelListWithIds);
                    } else {
                        setSubModels([]);
                        setShowAlert(true);
                        setAlertMessage("No Sub Models");
                    }
                    setShowHud(false);
                })
                .catch(error => {
                    console.error('Error fetching sub models:', error);
                    setShowHud(false);
                    setShowAlert(true);
                    setAlertMessage('Sub Models get: ' + error.message);
                });
        } else {
            setSubModels([]);
        }
    }, [selectedModel]);


    const handleSubmit = () => {
        if (selectedYear && selectedMake && selectedModel) {

            const subModelDetails = submodels.find(modelItem => modelItem.id === selectedSubModel);

            setShowHud(true);
            const reqObj = {
                year: selectedYear,
                make: selectedMake,
                model:selectedModel,
                engine: subModelDetails.engine,
                submodel: subModelDetails.submodel
            }
            console.log('handleSubmit the req obj:', reqObj);
            axios.post('http://vmapi-stage.us-east-1.elasticbeanstalk.com/api/maint_search/MaintPortal_PlanDetails_GET', reqObj)
                .then(response => {
                    console.error('response:', response.data);
                    setTableData(response.data.planDetailsList);
                    setShowTable(true);
                    setShowHud(false);
                   
                    if (response.data.planDetailsList.length > 0) {
                        setPlanName(response.data.planDetailsList[0].planName)
                    } else {
                        setPlanName('')
                        setShowAlert(true)
                        setAlertMessage("Data not found")
                    }

                })
                .catch(error => {
                    console.error('Error fetching plan details:', error);
                    setShowHud(false);
                    setShowAlert(true)
                    setAlertMessage('Plans get: '+error.message)
                });
        } else {
            console.error('Please select year, make, and model.');
            setShowHud(false);
        }
    };


    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);

        setTableData([]);
        setPlanName('')

        setMakes([]);
        setSelectedMake('');

        setModels([]);
        setSelectedModel('');

        setSubModels([]);
        setSelectedSubModel('');



        setShowTable(false);
    };

    const handleMakeChange = (e) => {
        setSelectedMake(e.target.value);
       
        setModels([]);
        setSelectedModel('');

        setSubModels([]);
        setSelectedSubModel('');

       
        setTableData([]);
        setPlanName('')
        setShowTable(false);
    };

    const handleModelChange = (e) => {
        setSelectedModel(e.target.value);
       
        setSubModels([]);
        setSelectedSubModel('');

        setTableData([]);
        setPlanName('')
        setShowTable(false);

        const selectedValue = e.target.value;
        setSelectedModel(selectedValue);
        
    };

    
    const handleSubModelChange = (e) => {
        setSelectedSubModel(e.target.value);
        setTableData([]);
        setPlanName('')
        setShowTable(false);

        const selectedValue = e.target.value;
        setSelectedSubModel(selectedValue);
        
        // Optionally, find the selected model details
        const selectedSubModelDetails = submodels.find(model => model.id === selectedValue);
        console.log("handleSubModelChange:",selectedSubModelDetails);

    };

    const handleClear = () => {
        setSelectedYear('');
       // setYears([]);

        setSelectedMake('');
        setMakes([]);

        setSelectedModel('');
        setModels([]);


        setSelectedSubModel('')
        setSubModels([]);

        
        setPlanName('');
        setShowTable(false);
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleDropdownOption = (option) => {
        if (option === 'changePassword') {
            navigate('/changepass');
        } else if (option === 'logout') {
            navigate('/');
        }
        setShowDropdown(false);
    };

    const uniqueMileages = [...new Set(tableData.map(item => item.mileageInterval))].sort((a, b) => a - b);
   

    function closePop() {
        setShowDropdown(false);
    }
    const toggleHud = () => {
        setShowHud(!showHud);
    };
    const handleAlertClose = () => {
        setShowAlert(false);
    };

    function formatMileage(mileage) {
        return mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleEdit = (mileage) => {
        const schedules = tableData.filter(item => item.mileageInterval === mileage);
        const schedulesArr = {
            mileage: mileage,
            planName: schedules[0].planName,
            schedules: schedules
        }
        navigate('/edit-schedule', { state: { schedulesArr } });
    }
    return (
        <div style={{ backgroundColor: '#f6f9fa', height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <div className="header" style={{ height: '70px', width: '100%', backgroundColor: '#397fc4', position: 'fixed', top: 0, zIndex: 999 }}>
                <span className="welcome" style={{ fontSize: "24px", paddingLeft: '20px', lineHeight: '70px', color: 'white' }}>Vehicle Maintenance Application Portal</span>
                <div className="position-absolute" style={{ top: '10px', right: '10px', display: "flex", alignItems: 'center' }}>
                    <button className="btn btn-light me-2" onClick={() => navigate('/add-vehicle')}>Add Vehicle</button>
                    <button className="btn btn-light me-2" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }} onClick={() => navigate('/')}>
                        <IoMdLogOut style={{ marginRight: '5px', width: '23px', height: '23px', color: '#397fc4' }} />
                        Logout
                    </button>

                    <div id="dropdown" style={{ marginLeft: '10px', position: 'relative' }} onClick={(e) => {
                        if (e.target.id === 'dropdown') {
                            closePop();
                        }
                    }}>
                        <div style={{ width: '50px', height: '50px', backgroundColor: 'transparent', alignContent: 'center' }} onClick={toggleDropdown}>
                            <IoMdSettings style={{ width: '30px', height: '30px', color: 'white' }} />
                        </div>
                        {showDropdown && (
                            <div style={{ position: 'absolute', top: '60px', right: '0px', backgroundColor: '#fff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', borderRadius: '5px', zIndex: 1, width: '150px' }}>
                                <button style={{ width: '100%', padding: '7px', fontSize: '16px', color: '#333', backgroundColor: 'transparent', border: 'none', borderBottom: '1px solid #ccc', cursor: 'pointer', transition: 'background-color 0.3s ease' }} onClick={() => handleDropdownOption('changePassword')}>Change Password</button>
                            </div>
                        )}
                    </div>
                </div>


            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px', height: '90%', marginTop: '70px' }}>
                <div style={{ width: `${leftBoxWidth}px`, backgroundColor: 'white', padding: '15px', boxShadow: '0 8px 8px rgba(0, 0, 0, 0.1)', borderRadius: '5px', transition:'ease' }}>

                    <div style={{ backgroundColor: 'white', padding: '15px' }}>

                    <div style={{ marginBottom: '30px' }}>
                            <label htmlFor="year" className="form-label">Year:</label>
                            <select id="year" className="form-select" value={selectedYear} onChange={handleYearChange} style={{ width: '98%' }}>
                                <option value="">Select Year</option>
                                {years.map((year) => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>

                        <div style={{ marginBottom: '30px' }}>
                            <label htmlFor="make" className="form-label">Make:</label>
                            <select id="make" className="form-select" value={selectedMake} onChange={handleMakeChange} style={{ width: '98%' }}>
                                <option value="">Select Make</option>
                                {makes.map((make) => (
                                    <option key={make} value={make}>{make}</option>
                                ))}
                            </select>
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <label htmlFor="model" className="form-label">Model:</label>
                            <select
                                id="model"
                                className="form-select"
                                value={selectedModel}
                                onChange={handleModelChange}
                                style={{ width: '98%' }}
                            >
                                <option value="">Select Model</option>
                                {models.map((model) => (
                                    <option key={model} value={model}>{model}</option>
                                ))}
                            </select>
                            {showHud && <div>Loading...</div>}
                            {showAlert && <div>{alertMessage}</div>}
                        </div>

                        <div style={{ marginBottom: '30px' }}>
                            <label htmlFor="model" className="form-label">Sub Model:</label>
                            <select
                                id="model"
                                className="form-select"
                                value={selectedSubModel}
                                onChange={handleSubModelChange}
                                style={{ width: '98%' }}
                            >
                                <option value="">Select Sub Model</option>
                                {submodels.map((modelItem) => (
                                    <option
                                        key={modelItem.id}
                                        value={modelItem.id}
                                    >
                                        {`${modelItem.engine} - ${modelItem.submodel}`}
                                    </option>
                                ))}
                            </select>
                            {showHud && <div>Loading...</div>}
                            {showAlert && <div>{alertMessage}</div>}
                        </div>

                     
                        <div className="d-flex justify-content-end">
                            <button onClick={handleClear} className="btn btn-danger" style={{ width: '75px', marginRight: '10px', backgroundColor: 'gray', borderColor: 'gray' }}>Clear</button>
                            <button onClick={handleSubmit} className="btn btn-primary" style={{ width: '75px', marginRight: '10px', backgroundColor: 'rgb(57, 127, 196)', borderColor: 'rgb(57, 127, 196)' }}>Search</button>
                        </div>

                        <div
                        className="resize-handle"
                        onMouseDown={handleMouseDown}
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: `calc(100% - ${leftBoxWidth}px - 20px)`,
                            bottom: 0,
                            width: '10px',
                            cursor: 'col-resize',
                            zIndex: 1,
                        }}
                    />
                    </div>
                    
                </div>

                <div style={{ width: `calc(100% - ${leftBoxWidth}px - 30px)`, backgroundColor: 'white', padding: '15px', boxShadow: '0 8px 8px rgba(0, 0, 0, 0.1)', borderRadius: '5px', overflow: 'hidden' }}>
                    <div className="container d-flex flex-column align-items-center" style={{ flex: 1, padding: '15px' }}>
                     

                        
                        <div style={{ width: '100%', height: '85vh' }}>
                            {planName.length>0 && 
                            <>
                             <span style={{ fontSize: "20px", fontFamily: "Arial", color: '#397fc4' }}>{planName}</span><br />
                            <hr />
                            </>
                           
                            }

                            {showTable && (
                                <ul className="list-group" style={{ width: '100%', overflowX: 'auto', height: '75vh', overflow: 'auto' }}>
                                    {uniqueMileages.map((mileage, index) => (
                                        <li key={index} className="list-group-item">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop:'20px', marginBottom:'5px' }}>
                                                <h5>Mileage Interval: {formatMileage(mileage)} miles</h5>
                                                <button
                                                    onClick={() => handleEdit(mileage)}
                                                    style={{ width: "50px", backgroundColor:'#397fc4',border:'0px',  borderRadius:'4px', color:'white', marginTop:'-5px'}}>
                                                    Edit

                                                </button>
                                            </div>
                                            <table className="table table-bordered" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '25%' }}>Action Required</th>
                                                        <th style={{ width: '25%' }}>Part Name</th>
                                                        <th style={{ width: '50%' }}>Notes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.filter(item => item.mileageInterval === mileage).map((item, idx) => (
                                                        <tr key={idx}>
                                                            <td style={{ width: '25%' }}>{item.operationDescription}</td>
                                                            <td style={{ width: '25%' }}>{item.partName}</td>
                                                            <td style={{ width: '50%' }}>{item.notes}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>

            </div>
            {showAlert && (
                <Alert message={alertMessage} onClose={handleAlertClose} />
            )}
            {showHud && <Hud onClose={toggleHud} />}
        </div>
    );
};

export default HomeScreen;