import React, { useState } from 'react';

const AddNewItemModal = ({ itemType, onClose, onSave }) => {
    const [description, setDescription] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleSave = () => {
        if (description.trim() === '') {
            setShowAlert(true);
        } else {
            onSave(itemType, description);
            onClose();
        }

      
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <div style={{
            position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            <div style={{
                backgroundColor: 'white', padding: '20px', borderRadius: '5px', width: '320px', height:'auto',boxShadow: '0 5px 15px rgba(0,0,0,0.3)'
            }}>
                <h4>Add New {itemType === 'part' ? 'Part' : 'Operation'}</h4>

                <div>
                    <input
                        className="custom-input"
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ width: '100%' }}
                        placeholder={`Enter ${itemType === 'part' ? 'Part' : 'Operation'} Description`}
                    />
                </div>

                <div style={{ textAlign: 'right',marginTop:'20px' }}>
                    <button className="btn btn-primary" onClick={onClose} style={{ marginRight: '10px', backgroundColor:'gray', border:'0px' }}>Cancel</button>
                    <button className="btn btn-primary" onClick={handleSave}>Create</button>
                </div>
                {showAlert && (
                    <div style={{ marginTop: '20px', color: 'red', textAlign: 'center' }}>
                        Description is mandatory
                        <button className="btn btn-primary" onClick={handleCloseAlert} style={{marginLeft:'10px', marginRight: '10px', backgroundColor:'gray', border:'0px' }}>Close</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddNewItemModal;
