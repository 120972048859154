import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Vehicle.css"
import { IoMdSettings, IoMdLogOut } from "react-icons/io";
import Hud from './Hud';
import Alert from './Alert';
import { MdEdit } from 'react-icons/md';


const Vehicle = () => {
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMake, setSelectedMake] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [years, setYears] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [planId, setPlanId] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showEditPlan, setShowEditPlan] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [planName, setPlanName] = useState('');


    const [plansArray, setPlansArray] = useState([]);
    const [showHud, setShowHud] = useState(false);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (showDropdown && event.target.id !== 'dropdown') {
                const button = document.getElementById('dropdown');
                if (button && !button.contains(event.target)) {
                    closePop();
                }
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [showDropdown, closePop]);

    useEffect(() => {
        // Function to calculate years from 2000 to current year + 10 years
        const calculateYears = () => {
            const currentYear = new Date().getFullYear();
            const endYear = currentYear + 10;
            const yearArray = [];
            for (let year = 2000; year <= endYear; year++) {
                yearArray.push(year);
            }
            return yearArray.sort((a, b) => b - a); // Sort years in descending order
        };

        // Set the calculated years to state
        setYears(calculateYears());

    }, []);

    useEffect(() => {
        // Fetch makes data based on the selected year

        get_make();
    }, []);

    function get_make() {
        setShowHud(true);
        axios.get('http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_search/MaintPortal_Makes_GET')
            .then(response => {
                if(response.data.makeList.length > 0) {
                    console.log('response.data.makeList', response.data.makeList);
                    setMakes(response.data.makeList.map(item => item.make));
                    setShowHud(false);
                } else {
                    setShowHud(false);
                    setShowAlert(true)
                    setAlertMessage("Data not found")
                }
                
            })
            .catch(error => {
                console.error('Error fetching makes:', error);
                setShowHud(false);
                setShowAlert(true)
                setAlertMessage('Makes get: '+error.message)
            });
    }



    useEffect(() => {
        if (selectedMake) {
            setShowHud(true);
            axios.post('http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_search/MaintPortal_Models_GET', { make: selectedMake })
                .then(response => {
                    console.log('MaintPortal_Models_GET:', response.data);
    
                    if (response.data.modelList && response.data.modelList.length > 0) {
                        // Add a unique ID to each model item
                        const modelListWithIds = response.data.modelList.map((modelItem, index) => ({
                            ...modelItem,
                            id: `${modelItem.model}-${modelItem.engine}-${modelItem.submodel}-${index}`
                        }));
                        setModels(modelListWithIds);
                    } else {
                        setModels([]);
                        setShowAlert(true);
                        setAlertMessage("No Models");
                    }
                    setShowHud(false);
                })
                .catch(error => {
                    console.error('Error fetching models:', error);
                    setShowHud(false);
                    setShowAlert(true);
                    setAlertMessage('Models get: ' + error.message);
                });
        } else {
            setModels([]);
        }
    }, [selectedMake]);
    


    const handleYearChange = (e) => {
        const year = parseInt(e.target.value);
        const yearExists = plansArray.some(plan => plan.year === year);
        if(yearExists) {
            setShowAlert(true)
            setAlertMessage('Plan already exist for selected year.')
        } else {
            setSelectedYear(e.target.value);
        }
    };

    const handleMakeChange = (e) => {
        setSelectedMake(e.target.value);
        setSelectedModel('');
        setPlansArray([]);
        setPlanName('')
        setSelectedPlan('');
    };

    const handleModelChange = (e) => {
        setSelectedModel(e.target.value);
        setPlansArray([]);
        setSelectedPlan('');
    };

    // check comment again
    const handleGetPlanId = () => {
        setShowModal(!showModal);
        setShowEditPlan(false);
        if (selectedMake && selectedModel) {
            const modelDetails = models.find(modelItem => modelItem.id === selectedModel);
            setShowHud(true);
            axios.post('http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_vehicle/MaintPortal_VehiclePlan_GET', { make: selectedMake, model: modelDetails.model, engine: modelDetails.engine, submodel: modelDetails.submodel })
                .then(response => {
                
                    if(response.data.planlist.length > 0) {
                        console.log(response.data.planlist)
                        setPlansArray(response.data.planlist)
                        setShowHud(false);
                    } else {
                        setShowHud(false);
                        setShowAlert(true)
                        setAlertMessage("Data not found")
                    }
                })
                .catch(error => {
                    console.error('Error fetching plan ID:', error);
                    setShowHud(false);
                    setShowAlert(true)
                    setAlertMessage('Plans get: '+error.message)
                });
        }
    };




    // const MpalnId = [...new Set(tableData.map(item => item.mplanID))];


    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleDropdownOption = (option) => {
        if (option === 'changePassword') {
            navigate('/changepass');
        } else if (option === 'logout') {
            navigate('/');
        }
        setShowDropdown(false);
    };

    const isGetPlansButtonDisabled = !selectedMake || !selectedModel;
    const handleReplicatePlan = () => {
        // Handle adding vehicle here

        if (selectedYear && selectedMake && selectedModel && selectedPlan) {
            setShowHud(true);
            const modelDetails = models.find(modelItem => modelItem.id === selectedModel);
            const reqObj = {
                countryCode: "USA",
                year: selectedYear,
                make: selectedMake,
                model: modelDetails.model,
                engine: modelDetails.engine,
                submodel: modelDetails.submodel,
                mplanID: selectedPlan.mplanID,
                actionByID: "1",
                actionByName: localStorage.getItem('email')
            }
            axios.post('http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_vehicle/MaintPortal_VehiclePlan_MAP', reqObj)
                .then(response => {
                    // Set the plan ID
                    console.log('the handleReplicatePlan:', response.data);
                    const mplanID = response.data.mplanID
                    console.log('the mplanID is:', mplanID);
                    setPlansArray([]);
                    setSelectedPlan('');
                    setShowHud(false);
                    getPlanDetailsByPlanID(mplanID);

                })
                .catch(error => {
                    console.error('Error handleReplicatePlan', error);
                    setShowHud(false);
                    setAlertMessage('Relicate Plan :', error.message)
                });
        }
        else {
            setShowHud(false);
            setShowAlert(true)
            setAlertMessage('Please select year')
            
        }

    };

    function getPlanDetailsByPlanID(planId) {
        setShowHud(true);
        axios.post('http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_vehicle/MaintPortal_PlanDetails_PlanID_GET', { mplanID: planId })
            .then(response => {
                // Set the plan ID
               console.log('getPlanDetailsByPlanID',response.data);
                if (response.data.planDetailsList.length > 0) {
                    setPlanName(response.data.planDetailsList[0].planName)
                    setShowEditPlan(true)
                    setTableData(response.data.planDetailsList);
                    console.log('the plans data is', response.data);
                    setShowHud(false);
                    setSelectedYear('');
                } else {
                    setSelectedYear('');
                    setShowHud(false);
                    setPlanName('')
                    setShowAlert(true)
                    setAlertMessage("Unable to get the plan from server")
                }
            })
            .catch(error => {
                setSelectedYear('');
                console.error('Error fetching plan ID:', error);
                setShowHud(false);
                setShowAlert(true)
                setAlertMessage('Please select year')
            });
    }

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    function closePop() {
        setShowDropdown(false);
    }

    const toggleHud = () => {
        setShowHud(!showHud);
    };


    const uniqueMileages = [...new Set(tableData.map(item => item.mileageInterval))].sort((a, b) => a - b);
   

    const handleEdit = (mileage) => {
        const schedules = tableData.filter(item => item.mileageInterval === mileage);
        const schedulesArr = {
            mileage: mileage,
            planName: schedules[0].planName,
            schedules: schedules
        }
        navigate('/edit-schedule', { state: { schedulesArr } });
    }

    function formatMileage(mileage) {
        return mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div style={{ backgroundColor: '#f6f9fa', height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <div className="header" style={{ height: '70px', width: '100%', backgroundColor: '#397fc4', position: 'fixed', top: 0, zIndex: 999 }}>
                <span className="welcome" style={{ fontSize: "24px", paddingLeft: '20px', lineHeight: '70px', color: 'white' }}>Vehicle Maintenance Application Portal</span>
                <div className="position-absolute" style={{ top: '10px', right: '10px', display: "flex", alignItems: 'center' }}>
                    <button className="btn btn-light me-2" onClick={() => navigate('/Home')}>Home</button>
                    <button className="btn btn-light me-2" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }} onClick={() => navigate('/')}>
                        <IoMdLogOut style={{ marginRight: '5px', width: '23px', height: '23px', color: '#397fc4' }} />
                        Logout
                    </button>
                    <div id="dropdown" style={{ marginLeft: '10px', position: 'relative' }} onClick={(e) => { if (e.target.id === 'dropdown') { closePop(); } }}>
                        <div style={{ width: '50px', height: '50px', backgroundColor: 'transparent', alignContent: 'center' }} onClick={toggleDropdown}>
                            <IoMdSettings style={{ width: '30px', height: '30px', color: 'white' }} />
                        </div>
                        {showDropdown && (
                            <div style={{ position: 'absolute', top: '60px', right: '0px', backgroundColor: '#fff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', borderRadius: '5px', zIndex: 1, width: '150px' }}>
                                <button style={{ width: '100%', padding: '7px', fontSize: '16px', color: '#333', backgroundColor: 'transparent', border: 'none', borderBottom: '1px solid #ccc', cursor: 'pointer', transition: 'background-color 0.3s ease' }} onClick={() => handleDropdownOption('changePassword')}>Change Password</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex" style={{ marginTop: '70px', padding: '15px', height: '90%' }}>
                <div className="p-3" style={{ overflowY: 'auto', width: '25%' }}>
                    <span style={{ fontSize: "30px", fontFamily: "Arial", color: '#397fc4' }}>Add Vehicle</span><br />
                    <hr />
                    <div className="d-flex flex-column align-items-start w-100 my-3">
                        <div className="form-group mb-3">
                            <label htmlFor="countrySelect">Country</label>
                            <select className="form-select" style={{ width: "300px" }}>
                                <option value="usa">USA</option>
                            </select>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="make" className="form-label">Make:</label>
                            <select
                                id="make"
                                className="form-select"
                                value={selectedMake}
                                onChange={handleMakeChange}
                                style={{ width: "300px" }}
                            >
                                <option value="">Select Make</option>
                                {makes.length > 0 && makes.map((make) => (
                                    <option key={make} value={make}>{make}</option>
                                ))}
                            </select>
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <label htmlFor="model" className="form-label">Model:</label>
                            <select
                                id="model"
                                className="form-select"
                                value={selectedModel}
                                onChange={handleModelChange}
                                style={{ width: '300px' }}
                            >
                                <option value="">Select Model</option>
                                {models.map((modelItem) => (
                                    <option
                                        key={modelItem.id}
                                        value={modelItem.id}
                                    >
                                        {`${modelItem.model} (${modelItem.engine}, ${modelItem.submodel})`}
                                    </option>
                                ))}
                            </select>
                            {showHud && <div>Loading...</div>}
                            {showAlert && <div>{alertMessage}</div>}
                        </div>


                        <div className="form-group mb-3">
                            <button
                                className="btn btn-primary"
                                onClick={handleGetPlanId}
                                disabled={isGetPlansButtonDisabled}
                                style={{ width: "300px", marginTop:'10px', backgroundColor:'#397fc4'}}
                            >
                                Get Plans
                            </button>
                        </div>
                        {selectedPlan && (
                            <div>
                                <div className="form-group mb-3">
                                    <label htmlFor="year" className="form-label">Year:</label>
                                    <select
                                        id="year"
                                        className="form-select"
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                        style={{ width: "300px" }}
                                    >
                                        <option value="">Select Year</option>
                                        {years.length > 0 && years.map((year) => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="mPlanId" className="form-label">Selected Plan:</label>
                                    <label className="form-control read-only-input">{selectedPlan.planName}</label>
                                </div>
                                <div className="form-group mb-3" style={{margin:'10px'}}>
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleReplicatePlan}
                                        style={{ width: "300px", marginTop:'10px', backgroundColor:'#397fc4' }}
                                    >
                                        Replicate Plan
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="p-3" style={{ overflowY: 'auto', width: '75%', height: '100%' }}>
                    {plansArray.length > 0 && (
                        <>
                            <span style={{ fontSize: "30px", fontFamily: "Arial", color: '#397fc4' }}>Plans</span><br />
                            <hr />
                            <ul className="list-group w-100" style={{ width: '100%', maxHeight: '80%', overflowX: 'auto' }}>
                                {plansArray.map((plan, index) => (
                                    <li key={index} className="list-group-item">
                                        <div className="form-check">
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                name="planOption"
                                                id={`planOption${index}`}
                                                value={plan}
                                                onChange={() => setSelectedPlan(plan)}
                                            />
                                            <label className="form-check-label" htmlFor={`planOption${index}`}>
                                                {plan.planName}
                                                <br />
                                                Site Ref: {plan.planSiteReference}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {showEditPlan && (
                        <>
                            <span style={{ fontSize: "30px", fontFamily: "Arial", color: '#397fc4' }}>Replicated Plan </span><br />

                            <hr />

                            <div style={{ width: '100%' }}>


                                <span style={{ fontSize: "16px", fontFamily: "Arial", color: '#397fc4' }}>{planName}</span><br /><br />

                                <ul className="list-group w-100" style={{ maxWidth: '100%', maxHeight: '80%', overflowY: 'auto' }}>
                                    {uniqueMileages.map((mileage, index) => (
                                        <li key={index} className="list-group-item">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop:'20px', marginBottom:'5px' }}>
                                                <h5>Mileage Interval: {formatMileage(mileage)} miles</h5>
                                                <button
                                                    onClick={() => handleEdit(mileage)}
                                                    style={{ width: "50px", backgroundColor:'#397fc4',border:'0px',  borderRadius:'4px', color:'white', marginTop:'-5px'}}>
                                                    Edit

                                                </button>
                                            </div>
                                            <table className="table table-bordered" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '25%' }}>Action Required</th>
                                                        <th style={{ width: '25%' }}>Part Name</th>
                                                        <th style={{ width: '50%' }}>Notes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.filter(item => item.mileageInterval === mileage).map((item, idx) => (
                                                        <tr key={idx}>
                                                            <td style={{ width: '25%' }}>{item.operationDescription}</td>
                                                            <td style={{ width: '25%' }}>{item.partName}</td>
                                                            <td style={{ width: '50%' }}>{item.notes}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                        <p>Plan ID: {planId}</p>
                    </div>
                </div>
            )}
            {showAlert && (
                <Alert message={alertMessage} onClose={handleAlertClose} />
            )}
            {showHud && <Hud onClose={toggleHud} />}
        </div>
    );

}

export default Vehicle