import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Hud from './Hud';
import Alert from './Alert';
import AddNewItemModal from './AddNewItemModal';

const EditSchedule = () => {
    const location = useLocation();
    const { mileage, planName, schedules } = location.state?.schedulesArr || {};
    const [scheduleData, setScheduleData] = useState(schedules || []);

    const [operations, setOperations] = useState([]);
    const [parts, setParts] = useState([]);

    const [showHud, setShowHud] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [newItemType, setNewItemType] = useState('');
    const [newMileage, setNewMileage] = useState(mileage);

    console.log('mileage:',mileage);
    console.log('planName:',planName);
    console.log('schedules:',schedules);
    console.log('scheduleData is:',scheduleData);


    useEffect(() => {
        // Fetch operations
        axios.get('http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_operation/MaintPortal_Operation_GET')
            .then(response => {
                setOperations(response.data.mOperationDetails);
            })
            .catch(error => {
                console.error('Error fetching operations:', error);
            });

        // Fetch parts
        axios.get('http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_part/MaintPortal_Part_GET')
            .then(response => {
                setParts(response.data.mPartDetails);
            })
            .catch(error => {
                console.error('Error fetching parts:', error);
            });
    }, []);


    const handleMileageChange = (value) =>{
        console.log('the text field Value is:',value);
        setNewMileage(value);
    }

    const handleInputChange = (index, field, value) => {
        // const newScheduleData = [...scheduleData];
        // newScheduleData[index][field] = value;
        // setScheduleData(newScheduleData);

        if (value === 'AddNewPart' || value === 'AddNewoperation') {
            setShowModal(true);
            setNewItemType(value === 'AddNewPart' ? 'part' : 'operation');
           // setScheduleIndexToAddPart(index);
           console.log('the selected item is',value === 'AddNewPart' ? 'part' : 'operation')
        } else {
            const newScheduleData = [...scheduleData];
            newScheduleData[index][field] = value;


            if (field === 'partID') {
                const selectedPart = parts.find(part => part.partID === parseInt(value));
                if (selectedPart) {
                    newScheduleData[index]['partDescription'] = selectedPart.partDescription;
                }
            } else if (field === 'operationID') {
                const selectedOperation = operations.find(operation => operation.operationID === parseInt(value));
                if (selectedOperation) {
                    newScheduleData[index]['operationDescription'] = selectedOperation.operationDescription;
                }
            }

            setScheduleData(newScheduleData);
        }
    };



    const handleRemoveSchedule = (index) => {
        if (window.confirm('Are you sure you want to remove this schedule?')) {
            const schedule = scheduleData[index];
    

            // If scheduleID is 0, just remove from the list without making an API call
            if (schedule.scheduleID === 0) {
                const newScheduleData = scheduleData.filter((_, i) => i !== index);
                setScheduleData(newScheduleData);
                return;
            }

            const deleteRequest = {
                scheduleID: schedule.scheduleID,
                actionByID: 1, // Replace with actual actionByID
                actionByName: localStorage.getItem('email')
            };
            console.log('deleteRequest is:',deleteRequest);
    
            setShowHud(true);
            axios.post('http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_schedule/MaintPortal_Schedule_DELETE', deleteRequest)
                .then(response => {
                    console.log('the response is:',response);
                    console.log('the data is:',response.data);
                    console.log('the code is:',response.data.message.code);
                    if (response.data.message.code === 200) {
                        const newScheduleData = scheduleData.filter((_, i) => i !== index);
                        setScheduleData(newScheduleData);
                        setShowHud(false);
                    } else {
                        console.error('Error removing schedule:', response.data.message);
                        setShowHud(false);
                    }
                })
                .catch(error => {
                    console.error('Error removing schedule:', error);
                    setShowHud(false);
                });
        }
    };
    
    const deleteThisSchedule = () => {
        setShowHud(true);

        const tempSchedule = schedules.length > 0 ? schedules[0] : '';
        const deleteRequest = {
            mIntervalID: tempSchedule.mIntervalID,
            actionByID: 1, // Replace with actual actionByID
            actionByName: localStorage.getItem('email')
        };
        console.log('deleteThisSchedule is:',deleteRequest);

        axios.post('http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_interval/MaintPortal_Interval_DELETE', deleteRequest)
            .then(response => {
                console.log('the response is:',response);
                console.log('the data is:',response.data);
                console.log('the code is:',response.data.message.code);
                if (response.data.message.code === 200) {
                    setShowHud(false);
                    setShowAlert(true);
                    setAlertMessage(response.data.message.description);
                    window.history.back()
                } else {
                    console.error('Error deleteThisSchedule:', response.data.message);
                    setShowHud(false);
                    setShowAlert(true);
                    setAlertMessage(response.data.message.description);
                }
            })
            .catch(error => {
                console.error('Error deleteThisSchedule:', error);
                setShowHud(false);
                setShowAlert(true);
                setAlertMessage(error.message);
            });
    }

    const handleAddSchedule = () => {

         // Extract mplanID from the first object in the schedules array or use a default value if the array is empty
         const mplanID = schedules.length > 0 ? schedules[0].mplanID : '';
         const mIntervalID = schedules.length > 0 ? schedules[0].mIntervalID : '';

         const tempSchedule = schedules.length > 0 ? schedules[0] : '';

        setScheduleData([...scheduleData, {
         
            mIntervalID:tempSchedule.mIntervalID,
            mplanID: tempSchedule.mplanID,
            countryCode:"USA",
            planName:planName,
            planSiteReference:tempSchedule.planSiteReference,
            mileageinterval: newMileage,
            mileageDescription:tempSchedule.mileageDescription,
            scheduleID: 0,
            warranty: '',
            serve: false,
            qualifier: '',
            lubenotes: '',
            notes: '',
            partID: 0,
            partName: '',
            partDescription: '',
            operationID: 0,
            operationDescription: '',
            actionByID: 1, // Replace with actual actionByID
            actionByName: localStorage.getItem('email'),
            
        }]);
    };

    const handleUpdate1 = () => {//schedule.mileageInterval
        const updatedSchedules = scheduleData.map(schedule => ({
            mIntervalID:schedule.mIntervalID,
            mplanID: schedule.mplanID,
            countryCode:"USA",
            planName:schedule.planName,
            planSiteReference:schedule.planSiteReference,
            mileageinterval: newMileage,
            mileageDescription:schedule.mileageDescription,
            scheduleID: schedule.scheduleID,
            warranty: schedule.warranty,
            serve: schedule.serve,
            qualifier: schedule.qualifier,
            lubenotes: schedule.lubenotes,
            notes: schedule.notes,
            partID: schedule.partID,
            partName: schedule.partName,
            partDescription: schedule.partDescription,
            operationID: schedule.operationID,
            operationDescription: schedule.operationDescription,
            actionByID: 1, // Replace with actual actionByID
            actionByName: localStorage.getItem('email'),
           
        }));

        const requestData = { schedules: updatedSchedules };

        console.log('the req data is:',requestData);

    };

    
    

    const handleUpdate = () => {


        const tempSchedule = schedules.length > 0 ? schedules[0] : '';

     const scheduleInterval = {
        mplanID:tempSchedule.mplanID,
        mIntervalID:tempSchedule.mIntervalID,
        mileageinterval:newMileage,
        actionByID:1,
        actionByName:localStorage.getItem('email')
     }


        const updatedSchedules = scheduleData.map(schedule => ({
            scheduleID:schedule.scheduleID,
            partID: schedule.partID,
            operationID: schedule.operationID,
            warranty: schedule.warranty,
            serve: schedule.serve,
            partName: schedule.partName,
            qualifier: schedule.qualifier,
            lubenotes: schedule.lubenotes,
            notes: schedule.notes,
        }));



        const requestData = { schedules: updatedSchedules, scheduleInterval: scheduleInterval };

        console.log('the handleUpdate req data is:',requestData);

        setShowHud(true);
        axios.post('http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_schedule/MaintPortal_ScheduleList_ADD', requestData)
            .then(response => {
                if (response.data.message.code === 200) {
                    console.log('successfully schedules:', response.data.message);
                    setAlertMessage('Schedules updated successfully');
                    setShowAlert(true);
                } else {
                    console.error('Error updating schedules:', response.data.message);
                    setAlertMessage('Error updating schedules: ' + response.data.message.description);
                    setShowAlert(true);
                }
                setShowHud(false);
            })
            .catch(error => {
                console.error('Error updating schedules:', error);
                setAlertMessage('Error updating schedules: ' + error.message);
                setShowAlert(true);
                setShowHud(false);
            });
    };
    const toggleHud = () => {
        setShowHud(!showHud);
    };

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    const handleAddNewItem = (type,description) => {

        if (description === null) {
            setAlertMessage("Description is mandatory");
            setShowAlert(true);
        }
        else{
            if(type === 'part')
            {
                handleAddNewPart(description);

            }
            else
            {
                handleAddNewOperation(description);

            }
        }
    }

    const handleAddNewPart = (description) => {
       
            const requestData = {
                actionByID: 1, // Replace with actual actionByID
                actionByName: localStorage.getItem('email'),
                partDescription:description
            };

            setShowHud(true);
            axios.post(`http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_part/MaintPortal_Part_ADD`, {MPart:requestData})
                .then(response => {
                    if (response.data.message.code === 200) {
                            console.log('response.data',response.data.message);
                    } else {
                        console.error(`Error adding new Item:`, response.data.message);
                    }
                    setShowHud(false);
                })
                .catch(error => {
                    console.error(`Error adding new item:`, error);
                    setShowHud(false);
                });
        
    };
    

    const handleAddNewOperation = (description) => {
       
        const requestData = {
            actionByID: 1, // Replace with actual actionByID
            actionByName: localStorage.getItem('email'),
            operationDescription:description
        };

        setShowHud(true);
        axios.post(`http://vehiclemaintaince-portal.eba-3ftjvm2q.us-east-1.elasticbeanstalk.com/api/maint_operation/MaintPortal_Operation_ADD`, {MOperation:requestData})
            .then(response => {
                if (response.data.message.code === 200) {
                        console.log('response.data',response.data.message);
                } else {
                    console.error(`Error adding new Item:`, response.data.message);
                }
                setShowHud(false);
            })
            .catch(error => {
                console.error(`Error adding new item:`, error);
                setShowHud(false);
            });
    
};


   return (
        <div style={{ backgroundColor: '#f6f9fa', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <div className="header" style={{ height: '70px', width: '100%', backgroundColor: '#397fc4', position: 'fixed', top: 0, zIndex: 999 }}>
                <span className="welcome" style={{ fontSize: '24px', paddingLeft: '20px', lineHeight: '70px', color: 'white' }}>{planName}</span>
            </div>
            <div style={{ marginTop: '80px', textAlign: 'left', marginLeft: '15px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <label className="form-label" style={{ marginTop: '5px', fontSize: '21px', color: 'Grey', marginRight: '10px' }}>Mileage Interval:</label>
                    <input
                        className="custom-input"
                        type="text"
                        value={newMileage}
                        style={{ fontSize: '20px', display: 'block' }}
                        onChange={(e) => handleMileageChange(e.target.value)}
                    />
                </div>
            </div>
            <div style={{ flex: 1, overflowY: 'auto', }}>
                {scheduleData.map((schedule, index) => (
                    <div key={index} style={{ margin: '20px', border: '1px solid #ccc', padding: '20px', marginBottom: '10px', position: 'relative', backgroundColor: 'white', boxShadow: '0 8px 8px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div key={'serve'} style={{ flex: '1 0 25%', marginBottom: '10px', marginRight: '10px' }}>
                                <label className="form-label">Serve</label>
                                <div>
                                    <input
                                        type="radio"
                                        id={`yes-${index}`}
                                        name={`serve-${index}`}
                                        value="true"
                                        checked={schedule['serve'] === true}
                                        onChange={(e) => handleInputChange(index, 'serve', e.target.value === 'true')}
                                    />
                                    <label htmlFor={`yes-${index}`} style={{ marginRight: '10px' }}>Yes</label>
                                    <input
                                        type="radio"
                                        id={`no-${index}`}
                                        name={`serve-${index}`}
                                        value="false"
                                        checked={schedule['serve'] === false}
                                        onChange={(e) => handleInputChange(index, 'serve', e.target.value === 'true')}
                                    />
                                    <label htmlFor={`no-${index}`}>No</label>
                                </div>
                            </div>
                            <div key={'warranty'} style={{ flex: '1 0 25%', marginBottom: '10px', marginRight: '10px' }}>
                                <label className="form-label">Warranty</label>
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={schedule['warranty']}
                                    onChange={(e) => handleInputChange(index, 'warranty', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div key={'partName'} style={{ flex: '1 0 25%', marginBottom: '10px', marginRight: '10px' }}>
                                <label className="form-label">Part Name</label>
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={schedule['partName']}
                                    onChange={(e) => handleInputChange(index, 'partName', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div key={'qualifier'} style={{ flex: '1 0 25%', marginBottom: '10px', marginRight: '10px' }}>
                                <label className="form-label">Qualifier</label>
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={schedule['qualifier']}
                                    onChange={(e) => handleInputChange(index, 'qualifier', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </div>

                            <div key={'part'} style={{ flex: '1 0 25%', marginBottom: '10px', marginRight: '10px' }}>
                                <label className="form-label">Part</label>
                                <select
                                    className="custom-input"
                                    value={schedule['partID']}
                                    onChange={(e) => handleInputChange(index, 'partID', e.target.value)}
                                    style={{ width: '100%' }}
                                >
                                    <option value="">Select Part</option>
                                    {parts.map(part => (
                                        <option key={part.partID} value={part.partID}>
                                            {part.partID} - {part.partDescription}
                                        </option>
                                    ))}
                                       <option value="AddNewPart">Add New Part</option>
                                </select>
                            </div>
                            <div key={'operation'} style={{ flex: '1 0 25%', marginBottom: '10px', marginRight: '10px' }}>
                                <label className="form-label">Operation</label>
                                <select
                                    className="custom-input"
                                    value={schedule['operationID']}
                                    onChange={(e) => handleInputChange(index, 'operationID', e.target.value)}
                                    style={{ width: '100%' }}
                                >
                                    <option value="">Select Operation</option>
                                    {operations.map(operation => (
                                        <option key={operation.operationID} value={operation.operationID}>
                                            {operation.operationID} - {operation.operationDescription}
                                        </option>
                                    ))}
                                    <option value="AddNewoperation">Add New Operation</option>
                                </select>
                            </div>
                            <div key={'notes'} style={{ flex: '1 0 25%', marginBottom: '10px', marginRight: '10px' }}>
                                <label className="form-label">Notes</label>
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={schedule['notes']}
                                    onChange={(e) => handleInputChange(index, 'notes', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div key={'lubenotes'} style={{ flex: '1 0 25%', marginBottom: '10px', marginRight: '10px' }}>
                                <label className="form-label">Lube Notes</label>
                                <input
                                    className="custom-input"
                                    type="text"
                                    value={schedule['lubenotes']}
                                    onChange={(e) => handleInputChange(index, 'lubenotes', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: '8px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <button className="btn btn-danger" onClick={() => handleRemoveSchedule(index)}>
                                Remove
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center', marginBottom: '20px' }} >
                <button className="btn btn-primary" onClick={handleUpdate} style={{ marginRight: '10px', backgroundColor: '#397fc4', border: '0px' }}>Save & Update</button>
                <button className="btn btn-primary" onClick={handleAddSchedule} style={{ marginRight: '10px', backgroundColor: '#397fc4', border: '0px' }}>Create New Item</button>
                <button className="btn btn-primary" onClick={deleteThisSchedule} style={{ marginRight: '10px', backgroundColor: '#397fc4', border: '0px' }}>Delete This Mileage Interval</button>
                <button className="btn btn-primary" onClick={() => window.history.back()} style={{ marginRight: '10px', backgroundColor: 'red', border: '0px' }}>Close</button>

            </div>
            {showAlert && (
                <Alert message={alertMessage} onClose={handleAlertClose} />
            )}
             {showModal && <AddNewItemModal itemType={newItemType} onSave={handleAddNewItem} onClose={() => setShowModal(false)} />}
         

            {showHud && <Hud onClose={toggleHud} />}
        </div>
    );
    
}

export default EditSchedule;
