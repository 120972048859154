import { BrowserRouter,Routes,Route } from "react-router-dom";
import ChangePassword from "./Access/changepass";
import Signin from "./Access/Signin";
import Signup from "./Access/Signup";
import ForgotPassword from "./Access/forgot";
import HomeScreen from "./Access/home";
import Vehicle from "./Access/Vehicle";
import EditSchedule from "./Access/EditSchedule";
import PasswordValidation from "./Access/PasswordValidation";

function App() {
  return (
   <BrowserRouter>
   <Routes>
    <Route path='/' element={<Signin/>}/>
    <Route path='/Home' element={<HomeScreen/>}/>
    <Route path='/Signup' element={<Signup/>}/>
    <Route path='/forgot' element={<ForgotPassword/>}/>
    <Route path='/changepass' element={<ChangePassword/>}/>
    <Route path='/add-vehicle' element={<Vehicle/>}/>
    <Route path='/edit-schedule' element={<EditSchedule/>}/>
    <Route path='/PasswordValidation' element={<PasswordValidation/>}/>
   </Routes>
   </BrowserRouter>
   
  );
}

export default App;
