import React, { useState,useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { HiUser } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import Hud from './Hud';
import Alert from './Alert';


import UserPool from '../UserPool';
import { CognitoUser, AuthenticationDetails} from "amazon-cognito-identity-js";


const Signin = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '' });
    const [focused, setFocused] = useState({
        email: false,
        password: false,
    });
    const navigate=useNavigate();

    useEffect(()=>{
        window.history.forward();
        const handlebackbutton = () => {
          window.history.forward();
        };
        window.history.pushState(null, null, window.location.pathname );
        window.addEventListener('popstate', handlebackbutton);
        return () => {
          window.removeEventListener('popstate', handlebackbutton);
        };
    });

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 8;
    };

    const handleSubmit = (e) => {
        
        e.preventDefault();

        let valid = true;
        let emailError = '';
        let passwordError = '';

        if (!email) {
            emailError = 'Email is required.';
            valid = false;
        } else if (!validateEmail(email)) {
            emailError = 'Please enter a valid email.';
            valid = false;
        }

        if (!password) {
            passwordError = 'Password is required.';
            valid = false;
        } else if (!validatePassword(password)) {
            passwordError = 'Password must be at least 8 characters.';
            valid = false;
        }

        setErrors({ email: emailError, password: passwordError });

        if (valid) {

            setIsLoading(true);
            console.log('email is:',email);   
            console.log('password is:',password);   

            const user = new CognitoUser({
                Username: email,          
                Pool: UserPool
              });

              try{

                const authDetails = new AuthenticationDetails({
                    UserName: email,
                    Password: password
                })

                user.authenticateUser(authDetails,{
                  onSuccess:(data) =>
                  {
                    console.log('on success:',data);   
                
                    localStorage.setItem('isUserLoggedIn','1')
                    localStorage.setItem('email', email);                   
                    console.log('Form is valid. Submitting...');
                    setIsLoading(false);
                    navigate('/home');
                    
                  },
                  onFailure:(err) =>
                  {
                      console.log('onfailure:',err);  
                      setIsLoading(false);
                      setShowAlert(true);
                      setAlertMessage(err.message)
                      localStorage.setItem('isUserLoggedIn','2')    
                  },
                  newPasswordRequired:(data) =>{
                    setIsLoading(false);
                    console.log(' newPasswordRequired:',data); 
                  }
                });

              }
              catch(error)
              {
                if (error.response) {
                  console.log('Response Error:', error.response.data);
                } else if (error.request) {
                  console.log('Request Error:', error.request);
                } else {
                  console.log('Error:', error.message);
                }
              }


           
        }
    };

    const getInputStyle = (field, value, isFocused) => {
        let borderColor = '#f3f3f3'; // Default border color
        if (isFocused) {
            borderColor = '#397fc4';
        } else if (errors[field]) {
            borderColor = 'red';
        } else if (field !== 'email' && value.length >= 8) {
            borderColor = 'green';
        } else if (field === 'email' && !errors.email && value && validateEmail(value)) {
            borderColor = 'green';
        }

        return {
            width: '100%',
            padding: '15px',
            boxSizing: 'border-box',
            backgroundColor: '#f2f2f2',
            outline: 'none',
            border: `1px solid ${borderColor}`,
            borderRadius: '5px'
        };
    };

    const toggleHud = () => {
        setIsLoading(!isLoading);
    };

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    return (
        <div style={{
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#d3d4cf'
        }}>
             <p style={{ fontSize: "30px", marginBottom: "90px", fontFamily: "Arial",color:'#397fc4' }}>Vehicle Maintenance Application Portal</p>
            <div style={{
                backgroundColor: 'white',
                width: '400px',
                padding: '20px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
                position: 'relative',
                boxSizing: 'border-box'
            }}>
                
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '-55px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    backgroundColor: '#397fc4',
                    color: 'white'
                }}>
                    <HiUser style={{ width: '100%', height: '100%', padding: '10px' }} />
                </div>
                <form onSubmit={handleSubmit} style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '60px' // Adjusted to accommodate icon
                }}>
                   
                    <p style={{ fontSize: "20px", marginBottom: "30px", fontFamily: "Arial",marginTop:"0px",color:'#397fc4' }}>Sign In</p>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                        <input
                            type='text'
                            value={email}
                            onChange={handleEmailChange}
                            onFocus={() => setFocused(prevState => ({ ...prevState, email: true }))}
                            onBlur={() => setFocused(prevState => ({ ...prevState, email: false }))}
                            placeholder='Enter Email'
                            style={getInputStyle('email', email, focused.email)}
                            required
                        />
                        {errors.email && <span style={{ color: 'red', fontSize: "12px" }}>{errors.email}</span>}
                    </div>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                        <input
                            type='password'
                            value={password}
                            onChange={handlePasswordChange}
                            onFocus={() => setFocused(prevState => ({ ...prevState, password: true }))}
                            onBlur={() => setFocused(prevState => ({ ...prevState, password: false }))}
                            placeholder='Enter Password'
                            style={getInputStyle('password', password, focused.password)}
                            required
                        />
                        {errors.password && <span style={{ color: 'red', fontSize: "12px" }}>{errors.password}</span>}
                    </div>
                    <button type="submit" style={{
                        width: '100%',
                        padding: '15px',
                        backgroundColor: '#397fc4',
                        color: 'white',
                        border: 'none',
                        fontSize: '20px',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}>Sign In</button>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: "20px"
                    }}>
                        <NavLink to="/Signup" exact>
                            <p style={{ margin: 0 ,color:'#397fc4' }}>Create Account</p>
                        </NavLink>
                        <NavLink to='/forgot' exact>
                            <p style={{ margin: 0 ,color:'#397fc4' }}>Forgot password?</p>
                        </NavLink>
                    </div>
                    {isLoading &&  <Hud onClose={toggleHud} />}

                </form>
            </div>
            {showAlert && (
                        <Alert message={alertMessage} onClose={handleAlertClose} />
                    )}
        </div>
    );
};

export default Signin;