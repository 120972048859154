import React, { useState } from 'react';
import { HiUser } from "react-icons/hi";
import { NavLink, useNavigate } from 'react-router-dom';
import Alert from './Alert';
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from '../UserPool';
import { useLocation } from 'react-router-dom';
import Hud from './Hud';

function PasswordValidation() {
    const [showAlert, setShowAlert] = useState(false);
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [errors, setErrors] = useState({ newPassword: '' });
    const [isSuccess, setIsSuccess] = useState(false);
    const [focused, setFocused] = useState({
        code: false,
        newPassword: false,
    });
    const location = useLocation();
    const [showHud, setShowHud] = useState(false);
    const email = location.state?.email || '';
    const navigate = useNavigate();
    //console.log("email:",email)

    const handleNewPasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);
    };
    const validatePassword = (newPassword) => {
        // Regular expressions for newPassword validation
        const lengthRegex = /.{8,}/;
        const numberRegex = /\d+/;
        const symbolRegex = /[\W_]+/;
        const uppercaseRegex = /[A-Z]+/;
        const lowercaseRegex = /[a-z]+/;

        // Check if newPassword meets all criteria
        const isValid =
            lengthRegex.test(newPassword) &&
            numberRegex.test(newPassword) &&
            symbolRegex.test(newPassword) &&
            uppercaseRegex.test(newPassword) &&
            lowercaseRegex.test(newPassword);

        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let valid = true;
        let newPasswordError = '';

        if (!validatePassword(newPassword)) {
            newPasswordError = 'NewPassword must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
            valid = false;
        }

        setErrors({ newPassword: newPasswordError });

        if (!valid) {
            return; // Exit early if password is invalid
        }

        const userData = {
            Username: email,
            Pool: UserPool,
        };

        setShowHud(true);
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: () => {
                setShowHud(false);
                setShowAlert(true);
                setCode('');
                setNewPassword('');
                setAlertMessage("Password reset confirmed successfully");
                setIsSuccess(true);
            },
            onFailure: (err) => {
                setShowHud(false);
                setIsSuccess(false);
                setShowAlert(true);
                setAlertMessage(err.message);
            },
        });
    };

    const toggleHud = () => {
        setShowHud(!showHud);
    };
    const handleAlertClose = () => {
        setShowAlert(false);
        if (isSuccess) {
            navigate('/');
        }
    };

    const getInputStyle = (field, isFocused) => {
        let borderColor = '#f3f3f3'; // Default border color
        if (isFocused) {
            borderColor = '#397fc4';
        }

        return {
            width: '100%',
            padding: '15px',
            boxSizing: 'border-box',
            backgroundColor: '#f2f2f2',
            outline: 'none',
            border: `1px solid ${borderColor}`,
            borderRadius: '5px'
        };
    };

    return (
        <div style={{
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#d3d4cf'
        }}>
            <div style={{
                backgroundColor: 'white',
                width: '400px',
                padding: '20px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
                position: 'relative',
                boxSizing: 'border-box'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '-55px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    backgroundColor: '#3b7bc4',
                    color: 'white'
                }}>
                    <HiUser style={{ width: '100%', height: '100%', padding: '10px' }} />
                </div>
                <form onSubmit={handleSubmit} style={{ width: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '60px' }}>
                    <p style={{ width: '120%', textAlign: 'center', marginLeft: '60px', color: '#626262' }}>Check your email for the verification code and enter it here</p><br />
                    <div style={{ marginBottom: '20px', width: '120%', marginLeft: '60px' }}>
                        <input
                            type='text'
                            placeholder='Enter Verification Code'
                            onFocus={() => setFocused(prevState => ({ ...prevState, code: true }))}
                            onBlur={() => setFocused(prevState => ({ ...prevState, code: false }))}
                            required
                            onChange={(e) => setCode(e.target.value)} // Update onChange handler
                            value={code} // Add value attribute to ensure controlled input
                            style={getInputStyle('code', focused.code)}
                        />
                    </div>
                    <div style={{ marginBottom: '20px', width: '120%', marginLeft: '60px' }}>
                        <input
                            type='password'
                            placeholder='Enter New Password'

                            onFocus={() => setFocused(prevState => ({ ...prevState, newPassword: true }))}
                            onBlur={() => setFocused(prevState => ({ ...prevState, newPassword: false }))}
                            required
                            onChange={handleNewPasswordChange}
                            value={newPassword} // Add value attribute to ensure controlled input
                            style={getInputStyle('newPassword', focused.newPassword)}
                        />
                        {errors.newPassword && <span style={{ color: 'red', fontSize: "12px" }}>{errors.newPassword}</span>}
                    </div>
                    <button type="submit" style={{
                        width: '120%',
                        padding: '15px',
                        marginLeft: '60px',
                        backgroundColor: '#397fc4',
                        color: 'white',
                        border: 'none',
                        fontSize: '20px',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}>Submit</button><br />
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "120%",
                    }}>
                        <NavLink to="/forgot" exact >
                            <p style={{ marginLeft: '40px', color: '#397fc4', marginBottom: '0' }}>Back</p>
                        </NavLink>
                    </div>
                </form>
                {showAlert && (
                    <Alert message={alertMessage} onClose={handleAlertClose} />
                )}
                {showHud && <Hud onClose={toggleHud} />}
            </div>
        </div>
    );
}

export default PasswordValidation;
