import React, { useState } from 'react';
import { HiUser } from "react-icons/hi";
import Alert from './Alert';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from '../UserPool';
import Hud from './Hud';
const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState(localStorage.getItem('password') || '');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false); // New state for showing the alert
  const [alertMessage, setAlertMessage] = useState(''); // New state for the alert message
  const[showHud,setShowHud]=useState(false);

  const [errors, setErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [focused, setFocused] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const validatePassword = (newPassword) => {
    const lengthRegex = /.{8,}/;
    const numberRegex = /\d+/;
    const symbolRegex = /[\W_]+/;
    const uppercaseRegex = /[A-Z]+/;
    const lowercaseRegex = /[a-z]+/;

    
    const isValid =
      lengthRegex.test(newPassword) &&
      numberRegex.test(newPassword) &&
      symbolRegex.test(newPassword) &&
      uppercaseRegex.test(newPassword) &&
      lowercaseRegex.test(newPassword);

    return isValid;
  };
  const validate = () => {
    const newErrors = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    };

    if (oldPassword.length < 8) {
      newErrors.oldPassword = 'Old password must be at least 8 characters long';
    }
    if (!validatePassword(newPassword)) {
      newErrors.newPassword = 'New password must be at least 8 characters long, include a number, a symbol, an uppercase letter, and a lowercase letter';
    }
    if (confirmPassword.length < 8) {
      newErrors.confirmPassword = 'Confirm password must be at least 8 characters long';
    }
    if (newPassword === oldPassword) {
      newErrors.newPassword = 'New password cannot be the same as the old password';
    }
    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = 'New password and confirm password must match';
    }

    setErrors(newErrors);
    return Object.values(newErrors).every(error => error === '');
  };

  // Function to authenticate user with Cognito
  const authenticateUser = (username, password, callback) => {
    if (typeof callback !== 'function') {
      throw new TypeError('callback must be a function');
    }

    const userData = {
      Username: username,
      Pool: UserPool,
    };

    const cognitoUser = new CognitoUser(userData);
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        callback(null, cognitoUser, result); // Pass the cognitoUser object along with the result
      },
      onFailure: (err) => {
        callback(err, null);
      },
    });
  };

  // Function to change user password
  const changeUserPassword = (oldPassword, newPassword, callback) => {
    if (typeof callback !== 'function') {
      throw new TypeError('callback must be a function');
    }

    const username = localStorage.getItem('email');
    if (!username) {
      callback(new Error("Username not found in local storage"));
      return;
    }

    authenticateUser(username, oldPassword, (authErr, cognitoUser, authResult) => {
      if (authErr) {
        callback(authErr);
        return;
      }

      // At this point, the user is authenticated, and we can change the password
      cognitoUser.changePassword(oldPassword, newPassword, (changeErr, result) => {
        if (changeErr) {
          callback(changeErr);
        } else {
          callback(null, result);
        }
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowHud(true);
    if (validate()) {
      const callback = (err, result) => {
        if (err) {
          console.error("Error changing password", err.message);
          setShowHud(false);
          setShowAlert(true);
          setAlertMessage(err.message);
        
          // Show error message to the user
          //alert(err.message);
        } else {
          console.log("Password changed successfully", result);
          // Show success message to the user
          //localStorage.setItem('oldPassword', newPassword);
          setShowAlert(true);
          setAlertMessage('Password changed successfully');
          //alert('Password changed successfully');
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('')
          setShowHud(false);

        }
      };
      changeUserPassword(oldPassword, newPassword, callback);

    }
  };
  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const getInputStyle = (field, value, isFocused) => {
    let borderColor = '#f3f3f3'; // Default border color
    if (isFocused) {
      borderColor = '#397fc4';
    } else if (errors[field]) {
      borderColor = 'red';
    } else if (value.length >= 8) {
      borderColor = 'green';
    }

    return {
      width: '100%',
      padding: '15px',
      boxSizing: 'border-box',
      backgroundColor: '#f2f2f2',
      outline: 'none',
      border: `1px solid ${borderColor}`,
      borderRadius: '5px'
    };
  };

  const toggleHud = () => {
    setShowHud(!showHud);
    
  };

  return (
    <div style={{
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#d3d4cf'
    }}>
      <div style={{
        backgroundColor: 'white',
        width: '400px',
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        position: 'relative',
        boxSizing: 'border-box'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '-55px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100px',
          height: '100px',
          borderRadius: '50%',
          backgroundColor: '#397fc4',
          color: 'white'
        }}>
          <HiUser style={{ width: '100%', height: '100%', padding: '10px' }} />
        </div>
        <form onSubmit={handleSubmit} style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '60px' // Adjusted to accommodate icon
        }}>
          <h3 style={{ color: '#626262', marginTop: '20px' }}>Reset Your Password</h3>
          <div style={{ marginBottom: '20px', width: '100%' }}>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              onFocus={() => setFocused(prevState => ({ ...prevState, oldPassword: true }))}
              onBlur={() => setFocused(prevState => ({ ...prevState, oldPassword: false }))}
              required
              placeholder='Old Password'
              style={getInputStyle('oldPassword', oldPassword, focused.oldPassword)}
            />
            {errors.oldPassword && <span style={{ color: 'red', display: 'block', marginTop: '5px', fontSize: '12px' }}>{errors.oldPassword}</span>}
          </div>
          <div style={{ marginBottom: '20px', width: '100%' }}>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onFocus={() => setFocused(prevState => ({ ...prevState, newPassword: true }))}
              onBlur={() => setFocused(prevState => ({ ...prevState, newPassword: false }))}
              required
              placeholder='New Password'
              style={getInputStyle('newPassword', newPassword, focused.newPassword)}
            />
            {errors.newPassword && <span style={{ color: 'red', display: 'block', marginTop: '5px', fontSize: '12px' }}>{errors.newPassword}</span>}
          </div>
          <div style={{ marginBottom: '20px', width: '100%' }}>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onFocus={() => setFocused(prevState => ({ ...prevState, confirmPassword: true }))}
              onBlur={() => setFocused(prevState => ({ ...prevState, confirmPassword: false }))}
              required
              placeholder='Confirm Password'
              style={getInputStyle('confirmPassword', confirmPassword, focused.confirmPassword)}
            />
            {errors.confirmPassword && <span style={{ color: 'red', display: 'block', marginTop: '5px', fontSize: '12px' }}>{errors.confirmPassword}</span>}
          </div>
          <button type="submit" style={{
            width: '100%',
            padding: '15px',
            backgroundColor: '#397fc4',
            color: 'white',
            border: 'none',
            fontSize: '20px',
            borderRadius: '4px',
            cursor: 'pointer'
          }}>Change Password</button>
        </form>
        {showAlert && (
          <Alert message={alertMessage} onClose={handleAlertClose} />
        )}
      </div>
      {showHud && <Hud onClose={toggleHud} />}
    </div>
  );
};

export default ChangePassword;
